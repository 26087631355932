*{
    margin:0;
    padding:0;
    text-decoration: none;
}
.menuContainer{
    display:flex;
}
main{
    width: 100%;
    padding: 20px;
    margin-left: 60px;;
}
.sidebar{
    background: rgb(6, 52, 66);
    color: #fff;
    height: 100vh;
    width: 200px;
    transition: all 0.5s;
    position: fixed;
    left: 0px;
}
.top_section{
    display: flex;
    align-items:center;
    padding:20px 15px;
}
.logo{
    font-size: 30px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
}
.link{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
}
.link:hover{
    background:lightskyblue;
    color: #000;
    transition: all 0.5s;
}
.active{
    background: lightskyblue;
    color: #000;
}
.icon, .link_text{
    font-size: 20px;
}
